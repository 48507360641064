@tailwind base;
@tailwind components;
@tailwind utilities;

/* improve font render across routes*/
@layer base {
    html {
        font-family: sans-serif;
    }

    body {
        font-family: "Lato", sans-serif;
    }
}

html body {
    @apply text-zinc-800 bg-white dark:text-zinc-50 dark:bg-gray-950;
}

h1, h2, h3 {
    @apply font-normal leading-tight tracking-tight text-gray-900 dark:text-white;
}

h1 {
    @apply text-lg sm:text-xl md:text-2xl;
}

h2 {
    @apply text-base sm:text-lg md:text-xl;
}

h3 {
    @apply text-base md:text-lg;
}

.text-primary {
    @apply text-gray-900 dark:text-white;
}

.text-secondary {
    @apply text-gray-600 dark:text-gray-400;
}

label {
    @apply text-gray-600 dark:text-gray-400;
}

p.text-primary {
    @apply font-light text-base leading-8;
}

p.text-secondary {
    @apply font-light text-xl leading-6;
}

input, textarea, select {
    @apply text-gray-900 dark:text-white ring-proof-blue;
}

.button {
    @apply select-none block rounded-full px-4 py-3 text-base;
}

.button.button-sm {
    @apply px-2 py-1.5;
}

.button[type=submit] {
    @apply select-none block rounded-full px-4 py-3 text-base;
}

.button-white {
    @apply bg-white text-gray-900 enabled:hover:bg-gray-200 ring-1 ring-inset ring-gray-800;
}

.button-purple {
    @apply bg-proof-purple text-white enabled:hover:bg-gray-200 enabled:hover:bg-proof-purple/70 focus-visible:outline-proof-purple;
}

.navigation-link {
    @apply select-none relative px-2 flex items-center justify-center text-base font-medium enabled:hover:opacity-50 transition-opacity duration-200 ease-out;
}

.navigation-link.mobile {
    @apply my-0 h-8;
}

.navigation-active {
    @apply border-b-2;
}

:focus-visible {
    @apply focus-visible:outline focus-visible:rounded focus-visible:outline-2 focus-visible:outline-offset-2;
}

input[disabled], button[disabled], select[disabled] {
    @apply disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
}

input.input-text, textarea.input-text {
    @apply block w-full rounded-md border-0 bg-white text-gray-900 dark:text-white dark:bg-gray-900 ring-1 ring-inset px-3.5 py-2 shadow-sm sm:text-sm sm:leading-6;
}

select.select-default {
    @apply block w-full rounded-md border-0 bg-white text-gray-900 dark:text-white dark:bg-gray-900 ring-1 ring-inset px-3.5 py-2 shadow-sm sm:text-sm sm:leading-6;
}

input.input-focus, select.select-focus {
    @apply ring-1 ring-inset focus:ring-2 focus:ring-inset;
}

input.input-error, textarea.input-error, select.input-error {
    @apply text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500;
}

.profile-menu {
    @apply min-w-20 absolute top-10 right-0 z-20 rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none;
}

button.profile-item, a.profile-item {
    @apply py-2 px-3 text-sm text-left text-gray-900 hover:bg-gray-200 w-full text-nowrap select-none block;
}

